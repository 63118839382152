export const editGame = (game) => {
    // eslint-disable-next-line default-case
    switch (game.game_type) {
        case 'TYPE_MATCH_ELEMENTS':
            return editGameSimpleMethod(game)
        case 'TYPE_QUIZ':
            return editQuizGame(game)
        case 'TYPE_CARDS':
            return editGameSimpleMethod(game)
        case 'TYPE_ANAGRAM':
            return editGameSimpleMethod(game)
        case 'TYPE_INSERT_LETTER':
            return editInsertLetterGame(game)
        case 'TYPE_FIND_COUPLE':
            return editGameSimpleMethod(game)
        case 'TYPE_OPEN_WINDOW':
            return editGameSimpleMethod(game)
        case 'TYPE_WRITE_ANSWER':
            return editGameSimpleMethod(game)
        case 'TYPE_WRITE_EXTENDED_ANSWER':
            return editGameSimpleMethod(game);
        case 'TYPE_GROUPS_DIVISION':
            return editGameSimpleMethod(game)
        case 'TYPE_YES_OR_NOT':
            return editYesOrNotGame(game)
        case 'TYPE_CUBES':
            return editCubesGame(game)
        case 'TYPE_SPACE_MATHEMATICS':
            return editCpaseMathemathicsGame(game)
        case 'TYPE_INSERT_LETTER_SECOND':
            return editGameSimpleMethod(game)
        case 'TYPE_CROSSWORD':
            return editGameSimpleMethod(game)
        case 'TYPE_CHECK_YOURSELF':
            return editGameSimpleMethod(game)
    }
}
const createHashtags = (gameHashtags) => {
    const obj = {}
    gameHashtags.forEach((el, index) => {
        if (index === 0) obj.first_hashtag = el.name
        if (index === 1) obj.second_hashtag = el.name
        if (index === 2) obj.third_hashtag = el.name
        if (index === 3) obj.fourth_hashtag = el.name
    })
    if (Object.keys(obj).length) return obj
}

const editGameSimpleMethod = (game) => {
    let object = {}
    object['name'] = game.name
    if (game.photo !== null) {
        object['photo'] = [{
            url: game.photo
        }]
    }
    if (createHashtags(game.hashtags)) { object['hashtags'] = [createHashtags(game.hashtags)] }
    else { object['hashtags'] = [] }
    object['tasks'] = game.game.tasks
    return object
}

const editInsertLetterGame = (game) => {
    let object = {}
    object['name'] = game.name
    object['tasks'] = game.game.tasks.answers
    object['question'] = game.game.tasks.question
    if (createHashtags(game.hashtags)) { object['hashtags'] = [createHashtags(game.hashtags)] }
    else { object['hashtags'] = [] }
    if (game.photo !== null) {
        object['photo'] = [{
            url: game.photo
        }]
    }
    return object
}

const editQuizGame = (game) => {
    let object = {}
    const answersArray = []
    object['name'] = game.name
    game.game.tasks.forEach((item, key) => {
        let answerObject = {}
        item.task_answers.forEach((item, key) => {
            answerObject[`answer${key + 1}`] = item.answer
            answerObject[`check${key + 1}`] = item.is_right
            answerObject[`photo${key + 1}`] = item.photo
        })
        answerObject['text'] = item.text
        answerObject['photo'] = item.photo
        answersArray.push(answerObject)
    })
    object['tasks'] = answersArray
    if (createHashtags(game.hashtags)) { object['hashtags'] = [createHashtags(game.hashtags)] }
    else { object['hashtags'] = [] }
    if (game.photo !== null) {
        object['photo'] = [{
            url: game.photo
        }]
    }
    return object
}

const editYesOrNotGame = (game) => {
    const obj = {}
    obj.name = game.name
    obj.notAnswers = game.game.notAnswers
    obj.yesAnswers = game.game.yesAnswers
    if (createHashtags(game.hashtags)) { obj['hashtags'] = [createHashtags(game.hashtags)] }
    else { obj['hashtags'] = [] }
    if (game.photo !== null) {
        obj['photo'] = [{
            url: game.photo
        }]
    }
    return obj
}

const editCubesGame = (game) => {
    const obj = {}
    obj.name = game.name
    obj.tasks = game.game.tasks
    if (createHashtags(game.hashtags)) { obj['hashtags'] = [createHashtags(game.hashtags)] }
    else { obj['hashtags'] = [] }
    if (game.photo !== null) {
        obj['photo'] = [{
            url: game.photo
        }]
    }
    return obj
}

const editCpaseMathemathicsGame = (game) => {
    let object = {}
    const answersArray = []
    object['name'] = game.name
    object['task'] = game.game?.task || ''
    game.game.tasks.forEach((item, key) => {
        let answerObject = {}
        item.task_answers.forEach((item, key) => {
            answerObject[`answer${key + 1}`] = item.answer
            answerObject[`check${key + 1}`] = item.is_right
        })
        answerObject['text'] = item.text
        answerObject['task'] = item.task
        answerObject['photo'] = item.photo
        answersArray.push(answerObject)
    })
    object['tasks'] = answersArray
    if (createHashtags(game.hashtags)) { object['hashtags'] = [createHashtags(game.hashtags)] }
    else { object['hashtags'] = [] }
    if (game.photo !== null) {
        object['photo'] = [{
            url: game.photo
        }]
    }
    return object
}