import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from "react";
import Fullscreen from 'react-fullscreen-crossbrowser';
import { connect } from 'react-redux';
import ReactStopwatch from 'react-stopwatch';

import { copy } from "../../../functions/generalFunction";

import circleLeft from 'assets/circleLeft.svg';
import circleLeftActive from 'assets/circleLeftActive.svg';
import circleRight from 'assets/circleRight.svg';
import circleRightActive from 'assets/circleRightActive.svg';
import globe from 'assets/globe.svg';

import { getBrowserFullscreenElementProp } from "functions/useFullscreenStatus";
import { useTranslation } from 'react-i18next';
import './media.css';
import './style.css';

function WriteExtendedAnswer(props) {
    const { t } = useTranslation();
    const [tasks, setTasks] = useState(props.one_exercises.game.tasks.slice(0)); // Задания
    const [activeTask, setActiveTask] = useState(0); // Позиция текущего задания
    const [answers, setAnswers] = useState(Array(tasks.length).fill('')); // Массив строк с ответами
    const [loading, setLoading] = useState(false);
    const [soundPlay, setSoundPlay] = useState(true); // Вкл/выкл музыки
    const [saveTime, setSaveTime] = useState(null); // Сохранение времени для отправки результата
    const [isFullscreenEnabled, setIsFullscreenEnabled] = useState(false); // Полноэкранный режим
    const [isFullscreenEnabledClass, setIsFullscreenEnabledClass] = useState(false); // Полноэкранный режим через класс
    const [timer, setTimer] = useState(null); // Таймер

    const [inputValue, setInputValue] = useState(''); // Состояние для одного текстового ответа
    const [result, setResult] = useState([]); // Состояние для хранения результата игры

    const temp = useRef();
    let audio = useRef();

    const startFunction = () => {
        const resultArray = tasks.map((task) => ({
            question: {
                photo: task.photo,
                text: task.text
            },
            answers: task.answer.toUpperCase(),
            enter_answers: '', // Ожидаем строку от пользователя
            max_point: task.answer.length,
            actual_point: 0,
            active: true,
            final_answer: false,
            number_of_attempts: 0
        }));

        setLoading(true);
        setResult(resultArray);
        setActiveTask(0);

        audio.current = new Audio(props.sound);
        audio.current.loop = true;

        if (props.play) {
            setTimer(
                <ReactStopwatch
                    seconds={0}
                    minutes={0}
                    hours={0}
                    onChange={({ minutes, seconds }) => {
                        setSaveTime(minutes + ':' + seconds);
                    }}
                    render={({ minutes, seconds }) => {
                        return (
                            <div className="gameTimer">
                                <p className="timer">
                                    {minutes}:{seconds}
                                </p>
                            </div>
                        );
                    }}
                />
            );
            audio.current.play();
            audio.current.currentTime = 0;
            audio.current.volume = 0.05;
        }
    };

    temp.current = startFunction;

    useEffect(() => {
        temp.current();
    }, [props.play]);

    useEffect(() => {
        return () => {
            audio.current.pause();
        };
    }, []);

    const checkAnswer = () => {
        const resultCopy = copy(result);
        const currentTask = resultCopy[activeTask];
        const userAnswer = inputValue.trim().toUpperCase();

        // Сохраняем ответ пользователя
        currentTask.enter_answers = userAnswer;

        // Проверяем правильность ответа
        if (userAnswer === currentTask.answers) {
            currentTask.actual_point = currentTask.max_point; // Все очки за правильный ответ
            currentTask.final_answer = true;
        }

        currentTask.active = false;
        currentTask.number_of_attempts++;

        setResult(resultCopy);

        if (activeTask + 1 < tasks.length) {
            // Если это не последний вопрос, переходим к следующему
            setActiveTask(activeTask + 1);
            setInputValue(''); // Очищаем поле для следующего ответа
        } else {
            // Это последний вопрос, сохраняем результат и завершаем игру
            audio.current.pause();

            // Небольшая задержка перед завершением, чтобы гарантировать, что состояние обновилось
            setTimeout(() => {
                props.endGameResults(saveTime, resultCopy); // Отправляем результат с последним обновленным ответом
            }, 500);
        }
    };


    const onClickDontKnown = () => {
        const resultCopy = copy(result);
        resultCopy[activeTask].enter_answers = 'Не знаю';
        resultCopy[activeTask].active = false;
        resultCopy[activeTask].final_answer = true;

        setResult(resultCopy);
        setTimeout(() => {
            if (activeTask + 1 < tasks.length) {
                setActiveTask(activeTask + 1);
                setInputValue('');
            }
        }, 2000);
    };

    return (
        <>
            {loading && (
                <Fullscreen
                    enabled={isFullscreenEnabled}
                    onClose={() => {
                        setIsFullscreenEnabled(false);
                        setIsFullscreenEnabledClass(false);
                    }}
                >
                    {props.animation}
                    <div className={`gameBlock ${props.background}`}>
                        <div className="gameBlock_header">
                            {timer}
                            <p className="gameBlock_header__title">{t(`tasks.type.${props.one_exercises.game_type}`)}: {props.one_exercises && props.one_exercises.name}</p>
                            {props.play && (
                                <div className="gameBlock_header__dontKnown" onClick={onClickDontKnown}>
                                    Не знаю
                                </div>
                            )}
                        </div>
                        <div className="gameBlock_content writeAnswer_content">
                            <div className="writeAnswerBlock">
                                <div className="writeAnswerBlock_question">
                                    <div className="quizesBlock_question_text">
                                        {tasks[activeTask].photo ? (
                                            <>
                                                <img src={tasks[activeTask].photo} alt="img" className="quizesBlock_question_img" />
                                                {tasks[activeTask].text && <p>{tasks[activeTask].text}</p>}
                                            </>
                                        ) : (
                                            <p>{tasks[activeTask].text}</p>
                                        )}
                                    </div>
                                    <div className="writeAnswerBlock_question__answerBlock">
                                        <textarea
                                            className="writeAnswerTextArea"
                                            value={inputValue}
                                            onChange={(e) => setInputValue(e.target.value)}
                                            placeholder="Введите ваш ответ здесь"
                                            style={{ width: '100%', height: '100px', padding: '10px' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gameBlock_footer">
                            <div className="gameBlock_footer__steps">
                                <img src={activeTask !== 0 ? circleLeftActive : circleLeft} alt="circleLeft" onClick={() => setActiveTask(activeTask - 1)} />
                                <p>{activeTask + 1} из {tasks.length}</p>
                                <img src={activeTask + 1 < tasks.length ? circleRightActive : circleRight} alt="circleRight" onClick={checkAnswer} />
                            </div>
                            <div className="gameBlock_footer__options">
                                {soundPlay
                                    ? <img src={props.icons.sound} alt="sound" onClick={() => audio.current.pause()} />
                                    : <img src={props.icons.soundoff} alt="soundoff" onClick={() => audio.current.play()} style={{ width: '32px', height: '32px' }} />
                                }
                                {(isFullscreenEnabled || isFullscreenEnabledClass)
                                    ? <FullscreenExitOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => setIsFullscreenEnabled(false)} />
                                    : <FullscreenOutlined style={{ fontSize: '30px', color: props.icons.color }} onClick={() => setIsFullscreenEnabled(true)} />
                                }
                            </div>
                        </div>
                    </div>
                </Fullscreen>
            )}
        </>
    );
}

function mapStateToProps(state) {
    return {
        game: state.game.game,
        one_exercises: state.one_exercises.one_exercises,
    };
}

export default connect(mapStateToProps)(WriteExtendedAnswer);
