/* eslint-disable default-case */
import { api_leaderBoard } from 'API/leaderBoard';
import { message } from 'antd';
import TableResult from 'components/components/tableResult/TableResult';
import { selectBackground } from 'functions/selectBackground';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import Leave from 'animation/autumn/Autumn';
import Cloud from 'animation/cloud/Cloud';
import Snow from "animation/snow/Snow";
import Star from 'animation/space/Space';
import Spring from 'animation/spring/Spring';
import Anagram from './anagram/Anagram';
import Cards from './cards/Cards';
import Cubes from './cubes/Cubes';
import GroupDivision from './groupDivision/GroupDivision';
import InsertLetter from './insertLetter/InsertLetter';
import MatchElement from './matchElements/MatchElements';
import OpenWindow from './openWindow/OpenWindow';
import Quiz from './quize/Quize';
import SpaceMAthematics from './spaceMathematics/SpaceMathematics';
import WriteAnswer from './writeAnswer/WriteAnswer';
import YesOrNot from './yesOrNot/YesOrNot';

import startGame from 'assets/startGame.svg';

import { countScore } from 'functions/countScore';
import { selectIconColor } from 'functions/selectIconColor';
import { selecSound } from 'functions/selectSounds';
import { fetchCreateLeaderBoardResult } from 'store/actions/leaderboardResults';
import CheckYourself from './checkYourself/CheckYourself';
import CrosswordPuzzle from './crosswordPuzzle/CrosswordPuzzle';
import FindCouple from './findCouple/FindCouple';
import InsertLetterSecond from './insertLetterSecond/InsertLetterSecond';
import './media.css';
import './style.css';
import WriteExtendedAnswer from "./writeExtendedAnswer/WriteExtendedAnswer";

function GameBlock(props) {
    const [play, setPlay] = useState(false)
    const [showTable, setShowTable] = useState(false) /*Состояние таблицы результатов */
    const [showModal, setShowModal] = useState(false) /*Состояние popup */
    const [tableData, setTableData] = useState(null) /*Состояние данных таблицы */
    const [restart, setRestart] = useState(false) /*Состояние для повтора игры */
    const { id } = useParams()

    const gameType = () => {
        let background = selectBackground(props.one_exercises.background)
        let animation = animationStatus(background)
        let icons = selectIconColor(props.one_exercises.background)

        switch (props.one_exercises.game_type) {
            case 'TYPE_MATCH_ELEMENTS':
                return <MatchElement endGameResults={endGameResults} play={play} background={background} animation={animation} restart={restart} icons={icons} sound={selecSound(props.one_exercises.background)} />
            case 'TYPE_QUIZ':
                return <Quiz endGameResults={endGameResults} play={play} background={background} animation={animation} restart={restart} icons={icons} sound={selecSound(props.one_exercises.background)} />
            case 'TYPE_CARDS':
                return <Cards play={play} background={background} animation={animation} icons={icons} sound={selecSound(props.one_exercises.background)} />
            case 'TYPE_ANAGRAM':
                return <Anagram endGameResults={endGameResults} play={play} background={background} animation={animation} restart={restart} icons={icons} sound={selecSound(props.one_exercises.background)} />
            case 'TYPE_INSERT_LETTER':
                return <InsertLetter endGameResults={endGameResults} play={play} background={background} animation={animation} restart={restart} icons={icons} sound={selecSound(props.one_exercises.background)} />
            case 'TYPE_FIND_COUPLE':
                return <FindCouple endGameResults={endGameResults} play={play} background={background} animation={animation} restart={restart} icons={icons} sound={selecSound(props.one_exercises.background)} />
            case 'TYPE_OPEN_WINDOW':
                return <OpenWindow endGameResults={endGameResults} play={play} background={background} animation={animation} icons={icons} sound={selecSound(props.one_exercises.background)} />
            case 'TYPE_WRITE_ANSWER':
                return <WriteAnswer endGameResults={endGameResults} play={play} background={background} animation={animation} restart={restart} icons={icons} sound={selecSound(props.one_exercises.background)} />
            case 'TYPE_WRITE_EXTENDED_ANSWER': // Добавляем новый тип игры
                return <WriteExtendedAnswer endGameResults={endGameResults} play={play} background={background} animation={animation} restart={restart} icons={icons} sound={selecSound(props.one_exercises.background)} />
            case 'TYPE_GROUPS_DIVISION':
                return <GroupDivision endGameResults={endGameResults} play={play} background={background} animation={animation} restart={restart} icons={icons} sound={selecSound(props.one_exercises.background)} />
            case 'TYPE_YES_OR_NOT':
                return <YesOrNot endGameResults={endGameResults} play={play} background={background} animation={animation} restart={restart} icons={icons} sound={selecSound(props.one_exercises.background)} />
            case 'TYPE_CUBES':
                return <Cubes play={play} background={background} animation={animation} icons={icons} sound={selecSound(props.one_exercises.background)} />
            case 'TYPE_SPACE_MATHEMATICS':
                return <SpaceMAthematics endGameResults={endGameResults} play={play} background={background} animation={animation} restart={restart} icons={icons} sound={selecSound(props.one_exercises.background)} />
            case 'TYPE_INSERT_LETTER_SECOND':
                return <InsertLetterSecond endGameResults={endGameResults} play={play} background={background} animation={animation} restart={restart} icons={icons} sound={selecSound(props.one_exercises.background)} />
            case 'TYPE_CROSSWORD':
                return <CrosswordPuzzle endGameResults={endGameResults} play={play} background={background} animation={animation} restart={restart} icons={icons} sound={selecSound(props.one_exercises.background)} />
            case 'TYPE_CHECK_YOURSELF':
                return <CheckYourself endGameResults={endGameResults} play={play} background={background} animation={animation} restart={restart} icons={icons} sound={selecSound(props.one_exercises.background)} />
        }
    }

    const animationStatus = (background) => {
        switch (background) {
            case 'winter_bg':
                return <Snow />
            case 'autumn_bg':
                return <Leave />
            case 'space_bg':
                return <Star />
            case 'spring_bg':
                return <Spring />
            case 'cloud_bg':
                return <Cloud />
            default: return <div className='withoutAnimationBlock' />
        }
    }

    const endGameResults = async (time, result) => {
        const object = {
            score: countScore(result, 'actual'),
            time: '00:' + time,
            exercise_id: id,
        }
        setShowModal(true)
        setTableData({
            time: '00:' + time,
            result
        })
        if (props.userMe !== null) {
            object['owner_id'] = props.userMe.id
            object['name'] = props.userMe.first_name + ' ' + props.userMe.last_name
        } else {
            object['name'] = props.fast_reg.full_name
        }

        if (props.group_id === 'all') {
            object['group_id'] = null
        } else {
            object['group_id'] = parseInt(props.group_id)
        }

        if (props.group_id !== 'watch') {
            await api_leaderBoard.CreateLeaderBoard(object)
                .then(async (res) => {
                    for (let i = 0; i < result.length; i++) {
                        const data = {
                            answers: {
                                answers: result[i].answers,
                                question: result[i].question.question
                            },
                            enter_answers: {
                                enter_answers: result[i].enter_answers
                            },
                            max_point: result[i].max_point,
                            actual_point: result[i].actual_point,
                            leaderboard_id: res.data.id
                        }
                        if (result[i].question.photo) data.photo = result[i].question.photo
                        if (result[i].question.text) data.text = result[i].question.text
                        await props.fetchCreateLeaderBoardResult(data)
                    }
                    message.success("Результаты успешно сохранены")
                })
        }
    }

    return (
        showTable
            ? <TableResult tableData={tableData} game_type={props.one_exercises.game_type} />
            : <div className='gameBlockPage'>
                {!play &&
                    <div className={!play ? "patternInfoBlock_video" : "patternInfoBlock_video active"} style={{ background: play && 'none', height: play && 'auto', }}>
                        <img src={startGame} alt="startVideo" onClick={() => setPlay(!play)} />
                    </div>
                }
                {gameType()}
                {showModal &&
                    <div className="gameModal">
                        <div className="gameModal_content">
                            <div className='gameModal_content__description'>
                                <p className='gameModal_content__description__title'>Вы завершили игру!</p>
                                {/* <p><span>{countScore(tableData.result, 'actual')}</span>/{countScore(tableData.result, 'max')}</p> */}
                                <p style={{
                                    fontWeight: 400,
                                    fontSize: '32px',
                                    lineHeight: '39px',
                                    color: '#C0C0C0'
                                }}><span style={{ color: '#219653' }}>{countScore(tableData.result, 'actual')}</span>/{countScore(tableData.result, 'max')}</p>
                                <p style={{ color: '#6F727A' }}>Вы успешно завершили игру, хотите попробовать ещё?</p>
                            </div>
                            <div className="gameModal_content__buttons">
                                <button
                                    onClick={() => {
                                        setRestart(!restart)
                                        setShowModal(false)
                                    }}
                                >
                                    Начать сначала
                                </button>
                                <button onClick={() => setShowTable(true)}>Результаты</button>
                            </div>
                        </div>
                    </div>
                }
            </div>
    );

}

function mapStateToProps(state) {
    return {
        one_exercises: state.one_exercises.one_exercises,
        userMe: state.userMe.userMe,
        fast_reg: state.userMe.fast_reg,
    }
}

function mapDispatvhToProps(dispatch) {
    return {
        fetchCreateLeaderBoardResult: (data) => dispatch(fetchCreateLeaderBoardResult(data)),
    }
}

export default connect(mapStateToProps, mapDispatvhToProps)(GameBlock);
