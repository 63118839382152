/* eslint-disable default-case */
import { selectBackground } from 'functions/selectBackground';

import Anagram from './anagram/Anagram';
import Cards from './cards/Cards';
import FindCouple from './findCouple/FindCouple';
import GroupDivision from './groupDivision/GroupDivion';
import InsertLetter from './insertLetter/InsertLetter';
import MatchElements from './matchElements/MatchElements';
import OpenWindow from './openWindow/OpenWindow';
import Quiz from './quiz/Quiz';
import WriteAnswer from './writeAnswer/WriteAnswer';
import WriteExtendedAnswer from "./writeExtendedAnswer/WriteExtendedAnswer";


import 'backgrounds/staticStyle.css';
import CheckYourself from './checkYourself';
import CrosswordPuzzle from './crosswordPuzzle/CrosswordPuzzle';
import Cubes from './cubes/Cubes';
import InsertLetterSecond from './insertLetterSecond/InsertLetterSecond';
import SpaceMathematics from './spaceMathematics/SpaceMathematics';
import './style.css';
import YesOrNot from './yesOrNot/YesOrNot';

function SwitchCardBg(props) {

    const switchCardBg = () => {
        switch (props.game_type) {
            case 'TYPE_MATCH_ELEMENTS':
                return <MatchElements game={props.game.tasks} />
            case 'TYPE_QUIZ':
                return <Quiz game={props.game.tasks} />
            case 'TYPE_CARDS':
                return <Cards game={props.game.tasks} />
            case 'TYPE_OPEN_WINDOW':
                return <OpenWindow game={props.game.tasks} />
            case 'TYPE_ANAGRAM':
                return <Anagram game={props.game.tasks} />
            case 'TYPE_WRITE_EXTENDED_ANSWER': // Новый case для WriteExtendedAnswer
                return <WriteExtendedAnswer game={props.game.tasks} />
            case 'TYPE_WRITE_ANSWER':
                return <WriteAnswer game={props.game.tasks} />
            case 'TYPE_FIND_COUPLE':
                return <FindCouple game={props.game.tasks} />
            case 'TYPE_GROUPS_DIVISION':
                return <GroupDivision game={props.game.tasks} />
            case 'TYPE_INSERT_LETTER':
                return <InsertLetter game={props.game.tasks} />
            case 'TYPE_YES_OR_NOT':
                return <YesOrNot game={props.game.notAnswers} />
            case 'TYPE_CUBES':
                return <Cubes game={props.game.tasks} />
            case 'TYPE_SPACE_MATHEMATICS':
                return <SpaceMathematics game={props.game.tasks} />
            case 'TYPE_INSERT_LETTER_SECOND':
                return <InsertLetterSecond game={props.game.tasks} />
            case 'TYPE_CROSSWORD':
                return <CrosswordPuzzle game={props.game.tasks} />
            case 'TYPE_CHECK_YOURSELF':
                return <CheckYourself game={props.game.tasks} />

        }
    }

    return (
        <div className={`exercisesCardBg ${selectBackground(props.background)}`}>
            {switchCardBg()}
        </div>
    );
}


export default SwitchCardBg;
