import { connect } from 'react-redux';

import Anagram from './Anagram';
import Cards from './Cards';
import CheckYourself from './CheckYourself';
import CrosswordPuzzle from './CrosswordPuzzle';
import Cubes from './Cubes';
import FindCouple from './FindCouple';
import GroupDivisions from './GroupDivisions';
import InsertLetter from './InsertLetter';
import InsertLetterSecond from './InsertLetterSecond';
import MatchElement from './MatchElement';
import OpenWindow from './OpenWindow';
import Quizes from './Quizes';
import SpaceMathematics from './SpaceMathematics';
import WriteAnswer from './WriteAnswer';
import YesOrNot from './YesOrNot';
import WriteExtendedAnswer from "./WriteExtendedAnswer";

function SelectFormType(props) {
    const switchForm = () => {
        // eslint-disable-next-line default-case
        switch (props.task_type) {
            case 'TYPE_MATCH_ELEMENTS':
                return <MatchElement />
            case 'TYPE_QUIZ':
                return <Quizes />
            case 'TYPE_CARDS':
                return <Cards />
            case 'TYPE_OPEN_WINDOW':
                return <OpenWindow />
            case 'TYPE_ANAGRAM':
                return <Anagram />
            case 'TYPE_WRITE_ANSWER':
                return <WriteAnswer />
            case 'TYPE_WRITE_EXTENDED_ANSWER': // Новый case
                return <WriteExtendedAnswer />
            case 'TYPE_FIND_COUPLE':
                return <FindCouple />
            case 'TYPE_GROUPS_DIVISION':
                return <GroupDivisions />
            case 'TYPE_INSERT_LETTER':
                return <InsertLetter />
            case 'TYPE_YES_OR_NOT':
                return <YesOrNot />
            case 'TYPE_CUBES':
                return <Cubes />
            case 'TYPE_SPACE_MATHEMATICS':
                return <SpaceMathematics />
            case 'TYPE_INSERT_LETTER_SECOND':
                return <InsertLetterSecond />
            case 'TYPE_CROSSWORD':
                return <CrosswordPuzzle />
            case 'TYPE_CHECK_YOURSELF':
                return <CheckYourself />
        }
    }

    return (
        <>
            {switchForm()}
        </>
    );
}

function mapStateToProps(state) {
    return {
        task_type: state.create_task.task_type,
    }
}


export default connect(mapStateToProps, null)(SelectFormType);
