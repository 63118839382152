import { pdfTaskInfo } from "lists/pdfTaskInfo";
import { forwardRef } from "react";
import { Anagram } from "./games/anagram/Anagram";
import { CrosswordPuzzle } from "./games/crosswordPuzzle/CrosswordPuzzle";
import { FindCouple } from "./games/findCouple/FindCouple";
import { GroupDivision } from "./games/groupDivision/GroupDisision";
import { InsertLetterSecond } from "./games/inserLetterSecond/InsertLetterSecond";
import { InsertLetter } from "./games/insertLetter/InsertLetter";
import { MatchElements } from "./games/matchElements/MatchElements";
import { Quiz } from "./games/quiz/Quiz";
import { SpaceMathematics } from "./games/spaceMathematics/SpaceMathematics";
import { WriteAnswer } from "./games/writeAnswer/WriteAnswer";
import YesOrNot from "./games/yesOrNot/YesOrNot";
import './style.css';
import WriteExtendedAnswer from "../games/writeExtendedAnswer/WriteExtendedAnswer";

const ComponentToPrint = forwardRef(({ game_type, game, one_exercises }, ref) => {

    const switchGameType = () => {
        // eslint-disable-next-line default-case
        switch (game_type) {
            case 'TYPE_MATCH_ELEMENTS':
                return <MatchElements game={game} />
            case 'TYPE_QUIZ':
                return <Quiz game={game} />
            case 'TYPE_ANAGRAM':
                return <Anagram game={game} />
            case 'TYPE_WRITE_ANSWER':
                return <WriteAnswer game={game} />
            case 'TYPE_WRITE_EXTENDED_ANSWER': // Новый тип
                return <WriteExtendedAnswer game={game} />
            case 'TYPE_FIND_COUPLE':
                return <FindCouple game={game} />
            case 'TYPE_GROUPS_DIVISION':
                return <GroupDivision game={game} />
            case 'TYPE_INSERT_LETTER':
                return <InsertLetter game={game} />
            case 'TYPE_SPACE_MATHEMATICS':
                return <SpaceMathematics game={game} />
            case 'TYPE_INSERT_LETTER_SECOND':
                return <InsertLetterSecond game={game} />
            case 'TYPE_CROSSWORD':
                return <CrosswordPuzzle game={game} />
            case 'TYPE_YES_OR_NOT':
                return <YesOrNot game={one_exercises.game} />
        }
    }

    return (
        <div ref={ref} className="pdfPage Pdf_task">
            <div className="pdfPage_header">
                <p>Дата: </p>
                <p>Имя:</p>
            </div>
            <div className="pdfPage_content">
                {pdfTaskInfo.find(el => el.game_type === game_type).info &&
                    <div className="pdfPage_content__description">
                        <p className="pdfPage_content__description__title">Задание:</p>
                        <p>{pdfTaskInfo.find(el => el.game_type === game_type).info}</p>
                    </div>
                }
                <div className="pdfPage_content__tasks">
                    {switchGameType()}
                </div>
            </div>
        </div>
    );
});

export default ComponentToPrint